<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Dropdown</h2>
    </template>
    <template #page-content>
      <b-notification
        type="is-danger"
        has-icon
        :closable="false"
        aria-close-label="Close notification"
        role="alert"
      >
        Multi select is on development
      </b-notification>
      <div class="r-card">
        <h3>Overview</h3>
        <p>
          Dropdown adalah daftar di mana item yang dipilih selalu terlihat
          sementara item lain terlihat sesuai permintaan dengan mengklik tombol
          dropdown. Dropdown biasanya digunakan untuk formulir.
        </p>
      </div>
      <r-card>
        <div class="columns is-multiline">
          <div class="column is-full">
            <h4>Basic Dropdown</h4>
            <b-dropdown aria-role="list" v-model="basic">
              <template #trigger="{ active }">
                <b-input
                  readonly
                  v-model="basic"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                ></b-input>
              </template>
              <b-dropdown-item
                aria-role="listitem"
                v-for="(option, o) in basicOptions"
                :value="option"
                :key="o"
              >
                {{ option }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- <div class="column is-full">
            <h4>Multiselect</h4>
            <div class="columns is-multiline">
              <div class="column is-6">
                <h5>Grouped</h5>
                <r-multi-select
                  v-model="multiselectGrouped"
                  :options="multiselectGroupedOptions"
                  group-field="type"
                  group-options="items"
                  field="title"
                  field-id="title"
                >
                  <template #selected="{ props }">
                    <span v-for="(tag, t) in props.tags" :key="t">
                      {{ t > 0 ? ', ' : '' }}{{ tag.title }}
                    </span>
                  </template>
                  <template #name="{ option }">
                    {{ option.title }}
                  </template>
                </r-multi-select>
              </div>
            </div>
            <div class="columns is-multiline">
              <h5>Autocomplete</h5>
              <div class="column is-6">
                <b-taginput
                  v-model="multiselectGrouped"
                  :data="filteredDataObj"
                  autocomplete
                  group-field="type"
                  group-options="items"
                  :open-on-focus="true"
                  icon-right="menu-down"
                  placeholder="Add a tag"
                >
                  <template #selected="props">
                    <b-tag
                      v-for="(tag, index) in props.tags"
                      :key="index"
                      :type="'r-tag'"
                      rounded
                      :tabstop="false"
                      ellipsis
                      closable
                      @close="removeTag(tag.id)"
                    >
                      {{ tag.title }}
                    </b-tag>
                  </template>
                  <template
                    v-slot="props"
                    class="is-flex is-align-items-center is-justify-content-flex-start"
                  >
                    {{ props }}
                    <b-checkbox></b-checkbox>
                    <span>{{ props.option.title }}</span>
                  </template>
                </b-taginput>
              </div>
            </div>
          </div> -->
        </div>
      </r-card>
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      basic: '',
      multiselectBasic: 'Apple',
      multiselectGrouped: [
        { id: 2, title: 'Banana' },
        { id: 1, title: 'Apple' },
      ],
      basicOptions: ['John Doe', 'Ran AMendes', 'Jonathan Raes'],
      multiselectBasicOptions: ['John Doe', 'Ran AMendes', 'Jonathan Raes'],
      multiselectGroupedOptions: [
        {
          type: 'Fruit',
          items: [
            { id: 1, title: 'Apple' },
            { id: 2, title: 'Banana' },
            { id: 3, title: 'Grapes' },
            { id: 4, title: 'Watermelon' },
          ],
        },
        {
          type: 'Vegetables',
          items: [
            { id: 1, title: 'Carrot' },
            { id: 2, title: 'Brocolli' },
            { id: 3, title: 'Onion' },
          ],
        },
      ],
    }
  },
  methods: {
    removeTag(id) {
      let multiselectGrouped = JSON.parse(
        JSON.stringify(this.multiselectGrouped)
      )
      let filtered = multiselectGrouped.filter((item) => item.id !== id)
      this.multiselectGrouped = filtered
    },
  },
  computed: {
    filteredDataObj() {
      const newData = []
      this.multiselectGroupedOptions.forEach((element) => {
        // const items = element.items.filter(item =>
        //     item.toLowerCase().indexOf(this.name.toLowerCase()) >= 0)
        newData.push({ type: element.type, items: element.items })
        // if (items.length) {
        // }
      })
      return newData
    },
  },
}
</script>
